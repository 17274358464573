.stereo,
.add-to-cart {
  color: white;
}

.stereo .sub-sections-container {
  display: flex;
  flex-wrap: wrap;
}

.sub-sections-container {
  margin-top: 40px;
}

.sub-container {
  margin: 20px 20px;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.stereo .sub-sections {
  margin: 0 auto;
  max-width: 400px;
  flex: 1;
}

.stereo,
.add-to-cart {
  color: white;
}

.products-container {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px; /* spacing between products */
  padding: 20px; /* padding for the entire products container */
}

.product-item {
  border: 1px solid #e5e5e5;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.product-item:hover {
  transform: scale(1.03); /* optional: slightly scale product on hover */
}

.product-image {
  width: 100%;
  max-width: 200px;
  object-fit: cover;
  margin-bottom: 10px;
}

.product-title {
  margin-bottom: 5px;
  font-size: 16px;
  text-align: center;
}

.product-price {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.add-to-cart {
  padding: 5px 10px;
  background-color: #333;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-to-cart:hover {
  background-color: #555;
}

.back-btn {
  --color: red;
  font-family: inherit;
  display: inline-block;
  width: 8em;
  height: 2.6em;
  line-height: 2.5em;
  margin: 20px;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--color);
  transition: color 0.5s;
  z-index: 1;
  font-size: 17px;
  border-radius: 6px;
  font-weight: 500;
  color: var(--color);
  left: -500px;
}

.back-btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--color);
  height: 150px;
  width: 200px;
  border-radius: 50%;
}

.back-btn:hover {
  color: #fff;
}

.back-btn:before {
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.back-btn:hover:before {
  top: -30px;
  left: -30px;
}

.back-btn:active:before {
  background: #ce0a0a;
  transition: background 0s;
}
