a {
  text-decoration: none;
  cursor: pointer;
}

li {
  list-style: none;
}

.navbar-div {
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 15vh;
  margin: 0 auto;
  width: 75%;
}

.navbar-links {
  display: flex;
}

.navbar-link {
  position: relative;
  font-size: 18px;
  color: white;
  margin: 0px 24px;
}

.nav-logo {
  height: 80px;
  padding-top: 16px;
}

.link__hover--effect:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 0%;
  bottom: -3px;
  left: 0;
  transition: all 300ms ease;
}
.link__hover--effect:hover:after {
  width: 100%;
  right: 0;
}

.link__hover--effect--white:after {
  background-color: white;
}

.footer-link--t {
  color: white;
}

button {
  background-color: black;
}

@media screen and (max-width: 600px) {
  .navbar-div {
    flex-direction: column;
    height: auto;
  }

  .nav-logo {
    height: 60px;
    padding-top: 8px;
    margin-left: 20px;
  }

  .navbar-links {
    flex-direction: row;
    margin: 10px 0;
  }

  .navbar-link {
    font-size: 12px;
    margin: 10px 16px;
  }

  button {
    width: 100%;
  }
}
