*{
  font-family: "nunito", sans-serif;
  margin: 0;
  padding: 0;
  transition: all 300ms ease;
  scroll-behavior: smooth;
}

body{
  background-color: black;
}