/* modalproduct.css */

.modal-overlay-product {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-product {
  display: flex;
  align-items: center;
  width: 80%;
  height: 80%;
  max-height: 100%;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0.871);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;
}

.modal-product-btn {
  position: absolute;
  top: 15px;
  right: 20px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 12px;
  width: 50px;
  height: 30px;
  cursor: pointer;
}
.add-to-cart-modal {
  background-color: red;
  color: white;
  padding: 8px;
  margin-top: 16px;
  width: 160px;
  border-radius: 16px;
}

.modal-product img {
  max-width: 100%;
  width: 400px;
  max-height: 100%;
  height: 400px;
  margin-bottom: 15px;
  margin: 0 auto;
}

.product-desc-container {
  margin: 0 auto;
  width: 50%;
}

.modal-product h2 {
  margin: 15px 0px;
  display: flex;
  align-items: center; /* To align text and arrow icon */
}

.delivery-info-list {
  display: flex;
  flex-direction: column;
}
.delivery-info-list li {
  list-style: circle;
  margin: 2px 0px;
}

.product-desc-container h2 {
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 5px; /* Added padding for a better hover effect */
}

.product-desc-header {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.product-desc-container h2:hover {
  background-color: #fd0101;
  color: white; /* Change text color on hover for better contrast */
}

.arrow {
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
}

.rotate {
  transform: rotate(-90deg);
}
