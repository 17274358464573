.section-gallery {
  display: flex;
  flex-direction: column;
}

.pic-card--img {
  width: 440px;
  height: 480px;
  transition: all 0.4s;
  border-radius: 32px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.705);
  font-size: 30px;
  font-weight: 900;
  position: relative;
  overflow: hidden; /* Added to ensure ::after covers exactly this area */
  margin: 0 auto;
}

.pic-card--img:hover {
  border-radius: 15px;
  cursor: pointer;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.705);
}

.first-content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.second-content {
  color: white;
  height: 100%;
  width: 100%;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
  z-index: 99;
  font-size: 1.8rem;
  position: absolute; /* Make absolute to ensure it's placed correctly */
  top: 0;
}

.pic-card--img:hover .second-content {
  opacity: 1;
}

.pic-card--img::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6); /* grey with some opacity */
  transition: all 0.4s;
  opacity: 0;
  z-index: 98;
  border-radius: 10px;
}

.pic-card--img:hover::after {
  opacity: 1;
  border-radius: 15px;
}

.insta-logo {
  margin-top: 16px;
  font-size: 52px;
}

.car-cards {
  display: flex;
  justify-content: space-evenly;
}

.container-pics {
  width: 100%;
}

.gallery-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.gallery-header {
  font-size: 60px;
}
.gallery-para {
  color: white;
  font-size: 32px;
}

/* Your existing CSS */
/* ... */

@media only screen and (max-width: 767px) {
  .container-pics {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pic--card {
    width: 300px;
  }
  .pic-card--img {
    width: 100%;
    height: auto;
    margin: 12px 0px;
    font-size: 20px;
  }

  .second-content {
    font-size: 1.4rem;
  }

  .insta-logo {
    font-size: 26px;
  }

  .car-cards {
    flex-direction: column;
  }

  .container-pics {
    width: 100%;
  }

  .gallery-title {
    padding: 20px;
  }

  .gallery-header {
    font-size: 28px;
  }

  .gallery-para {
    font-size: 16px;
  }
}

.gallery-cards {
  margin-top: 120px;
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: center;
}

.gallery-card {
  display: flex;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px white solid;
  animation: scroll 20s linear infinite;
}

.card-content {
  align-items: center;
  gap: 20px;
  color: white;
}

.card-image img {
  width: 300px; /* Adjust based on your preference */
  height: 200px; /* Adjust based on your preference */
  object-fit: cover;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.card-info {
  padding: 20px;
  flex: 1;
}

.card-info h3 {
  margin-top: 0;
}

.insta-logo {
  color: #c13584; /* Instagram color */
  margin-top: 10px;
}

.scrolling-wrapper {
  display: flex;
  overflow-x: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  margin-top: 60px;
}

.gallery-card {
  flex: 0 0 auto;
  width: 300px; /* Adjust based on your card size */
  margin-right: 20px;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
