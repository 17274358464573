
.footer-div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 50vh;
        background-color: #242424;
}

.footer-links{
        margin-top: 32px;
        display: flex;
}

.footer-links-div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
}

.footer-link{
        position: relative;
        color: white;
        margin: 0px 24px;
}

.footer-logo{
        padding: 20px;
}

.link__hover--effect:after {
        content: "";
        position: absolute;
        height: 3px;
        width: 0%;
        bottom: -3px;
        left: 0;
        transition: all 300ms ease;
      }
      .link__hover--effect:hover:after {
        width: 100%;
        right: 0;
      }
      
      
      
      .link__hover--effect--white:after {
        background-color: white;
      }

      .copyright{
        margin-top: 40px;
        color: white;
      }

      button{
        background-color: inherit;
        border: none;
        font-size: 16px;
      }

       
      @media screen and (max-width: 600px) {
        .footer-div {
            flex-direction: column;
            height: auto;
        }
    
        .footer-logo {
            height: 60px;
            padding-top: 8px;
            margin-left: 20px;
        }
    
        .footer-links {
            flex-direction: row;
            margin: 10px 0;
        }
    
        .footer-link {
            font-size: 12px;
            margin: 10px 16px;
        }

        .copyright{
                font-size: 12px;
        }
    
    }
    

    