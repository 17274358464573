/* modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-width: 500px;
    width: 90%;
}

.close-modal {
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 20px;
}

.modal-btn{
    background-color: white;
    padding: 12px;
    border-radius: 12px;
}