main {
  background-color: rgb(0, 0, 0);
}

section {
  min-height: 100vh;
  margin: 0px 0px 80px 0px;
}

.red {
  color: red;
}

.landing-img {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 85vh;
}

.landing-div {
  position: relative;
  display: flex;
  justify-content: center;
}

.landing-text {
  max-height: 100%;
  height: 100%;
  max-width: 100%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 40px;
  color: white;
  position: absolute;
  text-align: center;
}

.landing-text--header {
  margin-bottom: 80px;
}

.landing-btn {
  color: white;
  font-size: 24px;
  border: 2px solid white;
  border-radius: 16px;
  padding: 16px;
}

.landing-btn:hover {
  background-color: white;
  color: black;
}

.brands-div {
  opacity: 0.5;
  margin-top: 120px;
  max-width: 100%;
}

.brands-img {
  width: 800px;
}

.brands-title {
  font-size: 14px;
}

/* second section */

.section2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.s2-title {
  font-size: 40px;
  color: white;
}

.cards {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 100%;
  width: 100%;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
  border-radius: 10px;
}

.card {
  position: relative;
  background: #242424;
  width: 160px;
  height: 320px;
  border-radius: 10px;
  padding: 2rem;
  color: #aaa;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.card__image-container {
  margin: -2rem -2rem 1rem -2rem;
}

.card__image {
  opacity: 0;
  transition: all 0.5s ease-in;
}

.card__title {
  color: rgb(249, 68, 68);
  margin-top: 35px;
  margin-bottom: 12px;
  font-weight: 800;
  font-size: 18px;
  letter-spacing: 0.01em;
}

.card__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: -1rem;
  opacity: 0;
  transition: all 0.5s ease-in;
}

.icon {
  font-size: 120px;
  color: white;
}

.card__svg {
  position: absolute;
  left: 0;
  top: 115px;
}
.s2-title {
  opacity: 0;
  transition: all 0.5s ease-in;
}

.s2-title.in-view {
  opacity: 1;
}

.container.in-view .card__image {
  opacity: 1;
  transform: translateY(0) scale(1);
  filter: blur(0);
}

.container.in-view .card__content {
  transform: translateY(0);
  opacity: 1;
}

/* section 3 */

.section3-main-div {
  opacity: 0;
  position: relative;
  display: flex;
  height: 100%;
}

.section3-main-div.in-view {
  opacity: 1;
}

.section3-pic-div {
  position: relative;
  margin-left: 600px;
}

.section3-text-div {
  position: relative;
  width: 800px;
  height: 400px;
  margin-top: -30px;
  display: flex;
  align-items: right;
  justify-content: right;
}

.section3-text {
  padding: 16px;
  line-height: 1.5;
  font-size: 20px;
  color: white;
  height: 100%;
  vertical-align: top;
  position: absolute;
  top: 50%;
  transform: translateY(-20%);
}

.blob {
  position: absolute;
  width: 1200px;
  height: 1150px;
  right: -100px;
  top: -320px;
}

.merc {
  width: 1300px;
  position: absolute;
  right: -200px;
}

/* section 4 */

.clients {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-shadow {
  cursor: pointer;
  width: 900px;
  height: 440px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  border: 1px solid rgba(255, 0, 0, 0.2);
  transition: all 0.2s;
  box-shadow: 12px 12px 2px 1px rgba(255, 0, 0, 0.2);
}

.card-shadow:hover {
  box-shadow: -12px 12px 2px -1px rgba(255, 0, 0, 0.2);
}

.icon1 {
  vertical-align: top;
  font-size: 60px;
  color: red;
}

.card-para {
  margin: 30px 0px;
  font-size: 24px;
}

.arrows {
  color: red;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-40px);
  }
}

.fade-in {
  animation: fadeIn 0.1s forwards;
}

.fade-out {
  animation: fadeOut 0.1s forwards;
}

.btn--arrows {
  background-color: inherit;
}

/* modal */

@media screen and (max-width: 600px) {
  .landing-img {
    height: 40vh;
    margin-top: 50px;
  }

  .landing-text {
    font-size: 16px;
    top: 180px;
    left: 10px;
    max-width: 100%;
    width: 95%;
  }

  .landing-btn {
    font-size: 12px;
    padding: 8px;
  }

  .brands-img {
    width: 320px;
  }

  section {
    min-height: 90vh;
  }

  .section2 {
    flex-direction: column;
  }

  .cards {
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .container {
    margin: 80px 40px;
  }

  .card {
    width: 100%;
  }

  .s2-title {
    font-size: 24px;
    padding-bottom: 12px;
  }

  .icon {
    font-size: 60px;
  }

  .section3-main-div {
    flex-direction: column;
  }

  .section3-pic-div {
    margin-left: 0;
  }

  .section3-text-div {
    width: 100%;
    margin-top: 190px;
  }

  .section3-text {
    text-align: center;
    font-size: 16px;
  }

  .blob {
    width: 100%;
    height: 320px;
    right: 0;
    top: -90px;
  }

  .merc {
    width: 100%;
    right: 0;
  }

  .card-shadow {
    width: 90%;
    height: 220px;
  }

  .card-para {
    font-size: 18px;
  }

  .clients {
    font-size: 16px;
  }

  .card-para {
    font-size: 12px;
  }
  .btn--arrows {
    padding: 12px;
  }
  .test-container {
    margin: 0;
  }
  .animation {
    display: flex;
    flex-direction: row;
  }

  .card-shadow {
    height: 330px;
    padding: 12px;
    box-shadow: none;
  }

  .icon1 {
    font-size: 24px;
  }
}
/* FAQ SECTION */

.faq-main {
  color: #fcfcfc;
  font-family: "Raleway", sans-serif;
  overflow-x: hidden;
  margin-top: 40px;
}

.faq-header {
  font-size: 42px;
  border-bottom: 1px dotted #ff0000;
  padding: 24px;
}

.faq-content {
  margin: 0 auto;
  max-width: 1200px;
}

.faq-question {
  padding: 20px 0;
  margin-bottom: 24px;
  border-radius: 12px;
  background: hsla(0, 0%, 8%, 1);

  background: linear-gradient(
    315deg,
    hsla(0, 0%, 8%, 1) 38%,
    hsla(0, 100%, 28%, 1) 100%
  );

  background: -moz-linear-gradient(
    315deg,
    hsla(0, 0%, 8%, 1) 38%,
    hsla(0, 100%, 28%, 1) 100%
  );

  background: -webkit-linear-gradient(
    315deg,
    hsla(0, 0%, 8%, 1) 38%,
    hsla(0, 100%, 28%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#141414", endColorstr="#8E0000", GradientType=1 );
}

.panel-title {
  font-size: 24px;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 10px 10px 0 48px;
  display: block;
  cursor: pointer;
}

.panel-content {
  font-size: 20px;
  padding: 0px 14px;
  margin: 0 40px;
  height: 0;
  overflow: hidden;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  transition: 0.4s ease;
}

.panel:checked ~ .panel-content {
  height: auto;
  opacity: 1;
  padding: 14px;
}

.plus {
  position: absolute;
  margin-left: 20px;
  margin-top: 4px;
  z-index: 5;
  font-size: 42px;
  line-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

.panel:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.panel {
  display: none;
  width: 100%;
}
